import { WrapPageElementNodeArgs } from "gatsby";
import React from "react";
import { DefaultPageContext } from "./src/custom-gatsby";
import MainLayout from "./src/layouts/MainLayout";

// Wraps every page in a component
export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementNodeArgs<unknown, DefaultPageContext>) => {
  return <MainLayout {...props.pageContext}>{element}</MainLayout>;
};
